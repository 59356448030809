<script setup lang="ts">
defineProps({
    scale: {
        type: Number,
        default: 1
    },
    class: {
        type: String,
        default: ''
    }
});
const appConfig = useAppConfig();
const googlePlayUrl = appConfig.APP_STORE.ANDROID_URL;
</script>

<template>
    <div :style="{ transform: `scale(${scale})`, transformOrigin: 'center center' }" :class="class">
        <a :href="googlePlayUrl" target="_blank" 
           class="inline-flex items-center justify-center px-3 py-2 bg-white hover:opacity-90 rounded-lg w-full h-[56px]">
            <div class="flex gap-3 items-center">
                <img src="/public/icon/google-play-logo.svg" alt="" class="w-8">
                <div class="flex flex-col items-start">
                    <span class="text-xs text-black/80">GET IT ON</span>
                    <span class="text-base font-medium text-black">Google Play</span>
                </div>
            </div>
        </a>
    </div>
</template>
